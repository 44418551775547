import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from '../layouts/default/default.component';
import { FormComponent } from './form/form.component';
import { ListComponent } from './list/list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: 'event-add',
    component: FormComponent
  }, {
    path: 'event-edit/:id',
    component: FormComponent
  }, {
    path: 'event-list',
    component: ListComponent
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
