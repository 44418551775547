import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, AfterViewInit {

  constructor(
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.get_data();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('app', 'dashboard');
  }
  get_data() {
    var th = this;
    var datas = [];
    setTimeout(() => {
      $('#DataTable').DataTable({
        "bProcessing": true,
        "bServerSide": true,
        "sServerMethod": "POST",
        "sAjaxSource": environment.baseUrl + 'getEventList',
        destroy: true,
        data: datas,
        cache: false,
        order: [[0, 'DESC']],
        columnDefs: [{ "targets": 0, "visible": false }],
        stateSave: true,
        fnDrawCallback: function () {

          $('.btn_edit').click(function () {
            var id = $(this).data('id');
            var url = $(this).data('url');
            th.router.navigate([url + "/" + id]);
          })

          $('.btn_delete').click(function () {
            var id = $(this).data('id');

            /*  */
            Swal.fire({
              title: 'Are you sure delete!',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it'
            }).then((result) => {
              if (result.value) {
                $.ajax({
                  url: environment.baseUrl + 'deleteEvent',
                  method: 'POST',
                  data: { id: id },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)
                      th.get_data();
                    } else {
                      th.toastr.error(res.message)
                    }
                  }
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
              }
            })
            /*  */
          })
          $('.change_status').click(function () {
            var id = $(this).data('id');
            var status = $(this).data('status');


            $.ajax({
              url: environment.baseUrl + 'changeEventStatus',
              method: 'post',
              data: { id: id, status: status },
              dataType: 'json',
              success: function (res) {
                th.DefaultComponent.loaderHide()
                if (res.status) {
                  th.toastr.success(res.message)

                  th.get_data();
                } else {
                  th.toastr.error(res.message)
                }

              }
            });



            // th.router.navigate([url]);
          })

        }
      });
    }, 500);

  }

  remove(id) {
    var th = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'to Delete this record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        th.DefaultComponent.loaderShow()
        $.ajax({
          url: environment.baseUrl + 'deleteEvent',
          method: 'POST',
          data: { id: id },
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide()
            if (res.status) {
              th.toastr.success(res.message)
              th.get_data();
            } else {
              th.toastr.error(res.message)
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('cancel')
      }
    })

  }
}
