<ngx-ui-loader [fgsType]="spinner_type" [fgsSize]="60" [fgsColor]="fgsColor" [hasProgressBar]=false></ngx-ui-loader>
<div id="layout-wrapper">
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a routerLink="/dashboard" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo_small.png" alt="logo-sm-dark" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo.png" alt="logo-dark" height="20">
                        </span>
                    </a>

                    <a routerLink="/dashboard" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo_small.png" alt="logo-sm-light" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo.png" alt="logo-light" height="20">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn">
                    <i class="ri-menu-2-line align-middle"></i>
                </button>
            </div>

            <div class="d-flex">

                <div class="dropdown d-inline-block user-dropdown">
                    <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-2.jpg"
                            alt="Header Avatar">
                        <span class="d-none d-xl-inline-block ms-1">Admin</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <!-- item-->
                        <!-- <a class="dropdown-item" href="#"><i class="ri-user-line align-middle me-1"></i> Profile</a>
                        <a class="dropdown-item" href="#"><i class="ri-wallet-2-line align-middle me-1"></i> My
                            Wallet</a>
                        <a class="dropdown-item d-block" href="#"><span
                                class="badge bg-success float-end mt-1">11</span><i
                                class="ri-settings-2-line align-middle me-1"></i> Settings</a>
                        <a class="dropdown-item" href="#"><i class="ri-lock-unlock-line align-middle me-1"></i> Lock
                            screen</a>
                        <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item text-danger" href="javascript:void(0);" (click)="logout()"><i
                                class="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!-- ========== Left Sidebar Start ========== -->
    <div class="vertical-menu">

        <div data-simplebar class="h-100">

            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <!-- Left Menu Start -->
                <ul class="metismenu list-unstyled" id="side-menu">
                    <li class="menu-title">Menu</li>

                    <li>
                        <a routerLink="/dashboard" class="waves-effect">
                            <i class="ri-dashboard-line"></i>
                            <span>Dashboard</span>
                        </a>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <i class="ri-store-2-line"></i>
                            <span>Category</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/category-add">Add New</a></li>
                            <li><a routerLink="/category-list">List</a></li>
                        </ul>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <i class="ri-group-line"></i>
                            <span>Member</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/member-add">Add New</a></li>
                            <li><a routerLink="/member-list">List</a></li>
                        </ul>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <i class="ri-group-line"></i>
                            <span>Member Excel</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/excel-list">List</a></li>
                        </ul>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <i class="ri-advertisement-line"></i>
                            <span>Advertise</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/advertise-add">Add New</a></li>
                            <li><a routerLink="/advertise-list">List</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <i class="ri-advertisement-line"></i>
                            <span>Events</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/event-add">Add New</a></li>
                            <li><a routerLink="/event-list">List</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- Sidebar -->
        </div>
    </div>
    <!-- Left Sidebar End -->



    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
        <router-outlet></router-outlet>

        <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2021 © SVIA.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-end d-none d-sm-block">
                            Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://www.gteches.com/"
                                target="_blank">Gtech</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

</div>