import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {
  public advertiseForm: FormGroup;
  public isSubmit = false;
  categoryIcon: any = '';
  advertise_id: any = 0;
  advertise_member_id: any = 0;
  memberList: any = [];
  categoryList: any = [];
  memberAdsList: any = [];
  ads_list: any[] = [{
    display_order: '',
    image: '',
    thumb: ''
  }];
  constructor(
    private route: ActivatedRoute,
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private fb: FormBuilder,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.route.params.subscribe(params => this.advertise_id = params.id);

    this.advertiseForm = this.fb.group({
      id: ['0'],
      title: ['', [Validators.required, Validators.minLength(3)]],
      content: ['', [Validators.required, Validators.minLength(3)]],
    });
  }


  addAdsValue() {
    this.ads_list.push({
      display_order: '',
      image: '',
      thumb: '',
    });
  }
  removeAdsValue(i: number) {
    this.ads_list.splice(i, 1);
  }
  onFileChange(event, index) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#image" + index).val('');
        this.toastr.error("Only images are supported for product");
        return;
      }
      this.ads_list[index]['image'] = file;
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.ads_list[index]['thumb'] = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {

      this.DefaultComponent.loaderShow();
      var formData = new FormData();
      formData.append('title', formDetail.title);
      formData.append('content', formDetail.content);

      formData.append('id', formDetail.id);

      for (var i = 0; i < this.ads_list.length; i++) {
        if (this.ads_list[i].image) {
          formData.append('ads_image' + i, this.ads_list[i].image);
          this.ads_list[i]['thumb'] = '';
        }
      }
      formData.append('ads', JSON.stringify(this.ads_list));
      this.GeneralService.saveEvent(formData).subscribe((res: any) => {
        this.DefaultComponent.loaderHide()
        var data = res;
        if (data.status) {
          if (formDetail.id > 0) {
            this.toastr.success(data.message)
            this.router.navigate(['/event-list']);
          } else {
            this.toastr.success(data.message)
            location.reload();
          }
        } else {
          this.toastr.error(data.message)
        }
      }, (err: any) => {
        console.error('Error:-', err);
      });


    }
  }


  ngOnInit(): void {
  }
  setAdsDetail() {
    if (this.advertise_id) {
      var param = { id: this.advertise_id };
      this.GeneralService.getEventDetail(param).subscribe((data: any) => {
        if (data['status']) {

          this.advertiseForm.patchValue({
            id: data.ads_data.id,
            title: data.ads_data.title,
            content: data.ads_data.content,
          });
          if (data.ads_img_data.length > 0) {
            this.ads_list = [];
            for (var i = 0; i < data.ads_img_data.length; i++) {
              this.ads_list.push({
                display_order: data.ads_img_data[i]['display_order'],
                image: '',
                id: data.ads_img_data[i]['id'],
                thumb: environment.baseUrl + "event/" + data.ads_img_data[i]['event_image'],
              });
            }
          }





        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    }
  }
  update_ads_website(e, index) {
    this.ads_list[index]['display_order'] = e.target.value;
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');
    this.setAdsDetail();

    // $("#member").change(function () {
    //   var member_id = $(this).val();
    //   th.get_member_ads(member_id)
    // });
  }
}