<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Manage Event</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a routerLink="/event-list">Event</a></li>
                            <li class="breadcrumb-item active">Add New</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <h4 class="card-title">Add Event</h4>
                        <p class="card-title-desc"></p>
                        <form class="" [formGroup]="advertiseForm"
                            (ngSubmit)="isSubmit= true; submitForm($event, advertiseForm.valid, advertiseForm.value)">
                            <div class="row">

                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Title</label>
                                        <input class="form-control" type="text" id="title" name="title"
                                            formControlName="title">
                                        <span *ngIf="advertiseForm.get('title')?.errors && isSubmit">
                                            <span *ngIf="advertiseForm.get('title')?.errors?.required && isSubmit"
                                                class="text-danger">
                                                Please enter title
                                            </span>
                                            <span
                                                *ngIf="advertiseForm.get('title')?.errors?.maxlength || advertiseForm.get('title')?.errors?.minlength "
                                                class="text-danger">
                                                title should be of minimum 3 characters OR a maximum of 30
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Content</label>
                                        <textarea class="form-control" id="content" name="content"
                                            formControlName="content"></textarea>
                                        <span *ngIf="advertiseForm.get('content')?.errors && isSubmit">
                                            <span *ngIf="advertiseForm.get('content')?.errors?.required && isSubmit"
                                                class="text-danger">
                                                Please enter content
                                            </span>
                                            <span
                                                *ngIf="advertiseForm.get('content')?.errors?.maxlength || advertiseForm.get('content')?.errors?.minlength "
                                                class="text-danger">
                                                content should be of minimum 3 characters OR a maximum of 30
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label">Event</label>
                                    <div class="row mb-3" *ngFor="let dv of ads_list; let i = index;">

                                        <div class="col-lg-5">
                                            <input type="file" (change)="onFileChange($event,i)" id="image{{i}}"
                                                class="form-control">
                                            <img [src]="dv.thumb" *ngIf="dv.thumb" alt="member-icon"
                                                class="rounded avatar-sm mt-2">
                                        </div>
                                        <div class="col-lg-5">
                                            <input class="form-control" name="display_order"
                                                (input)="update_ads_website($event,i)" value="{{dv.display_order}}"
                                                type="text" placeholder="display_order">
                                        </div>
                                        <div class="col-lg-2">
                                            <button type="button" *ngIf="(i==0)" class="btn btn-success"
                                                (click)="addAdsValue()">+</button>

                                            <button type="button" *ngIf="(i>0)" class="btn btn-danger"
                                                (click)="removeAdsValue(i)">-</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div> <!-- end col -->
        </div>
        <!-- end row -->


        <!-- end row -->
    </div> <!-- container-fluid -->
</div>